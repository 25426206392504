import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import usePreviewData from '../utils/usePreviewData';
import { regroup } from '../utils';
import Cover from '../screens/homepage/Cover';
import Seo from '../components/seo';
import SlicesContainer from '../components/slices';

const Wrapper = styled.div`
  display: block;
  width: 100%;

  .bigtitle {
    font-size: 24px;
  }
`;

const NotFoundPage = ({ data: rawData }) => {
  const data = usePreviewData(rawData);
  const home = regroup(data.prismic404?.data || {});
  const content = home?.body || [];

  return (
    <Wrapper>
      <Seo title="IPONE" suffix="Passion First" />
      <Cover {...home?.cover} />
      <SlicesContainer content={content} />
    </Wrapper>
  );
};

export const query = graphql`
  query ($cmsLocaleGlob: String!) {
    prismic404(lang: { glob: $cmsLocaleGlob }) {
      data {
        # configurator_description
        # configurator_title
        # reinsurance_display
        cover_desktop {
          alt
          url
          dimensions {
            height
            width
          }
        }
        cover_mobile {
          alt
          url
          dimensions {
            height
            width
          }
        }
        cover_link {
          target
          url
        }
        cover_baseline {
          alt
          url
          dimensions {
            height
            width
          }
        }
        cover_products {
          alt
          url
          dimensions {
            height
            width
          }
        }
        body {
          ... on Prismic404DataBodyTuto {
            id
            slice_type
            primary {
              darkmode
              title
            }
          }
          ... on Prismic404DataBodyStories {
            id
            slice_type
            primary {
              title
            }
            items {
              story {
                document {
                  ... on PrismicStories {
                    id
                    url
                    data {
                      title
                      description {
                        html
                      }
                      cover_title {
                        url
                        dimensions {
                          height
                          width
                        }
                        alt
                      }
                      cover_banner
                      cover_background {
                        dimensions {
                          height
                          width
                        }
                        alt
                        url
                      }
                      video_full {
                        embed_url
                        html
                      }
                      video_preview
                    }
                  }
                }
              }
            }
          }
          ... on Prismic404DataBodyCarousel {
            id
            slice_type
            primary {
              title
              darkmode
            }
            items {
              title
              link {
                url
                target
              }
              image {
                url
                dimensions {
                  height
                  width
                }
                alt
              }
            }
          }
          ... on Prismic404DataBodyArticle {
            id
            slice_type
            primary {
              title
              article {
                id
                document {
                  ... on PrismicArticles {
                    id
                    tags
                    url
                    type
                    uid
                    date: first_publication_date
                    data {
                      body {
                        ... on PrismicArticlesDataBodyContenu {
                          id
                          slice_type
                          primary {
                            content {
                              text
                            }
                          }
                        }
                      }
                      thumbnail {
                        url
                        alt
                        dimensions {
                          height
                          width
                        }
                      }
                      cover {
                        alt
                        url
                        dimensions {
                          height
                          width
                        }
                      }
                      title
                    }
                  }
                }
              }
            }
          }
          ... on Prismic404DataBodyPromo {
            id
            slice_type
            primary {
              title
              link {
                url
                target
              }
              imageMobile: image_mobile {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
              image {
                dimensions {
                  height
                  width
                }
                url
                alt
              }
            }
          }
          ... on Prismic404DataBodyLubadvisor {
            id
            slice_type
          }
        }
      }
    }
  }
`;

export default NotFoundPage;
